import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "usestate-useeffect-and-usecontext"
    }}>{`useState, useEffect, and useContext`}</h2>
    <p>{`React comes with
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html"
      }}>{`a bunch of basic and advanced hooks`}</a>{`.
The core hooks are:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`useState`}</inlineCode>{` for managing state`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`useEffect`}</inlineCode>{` for side-effects`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`useContext`}</inlineCode>{` for React's context API`}</li>
    </ul>
    <p>{`Here's how to think about them in a nutshell 👇`}</p>
    <h3 {...{
      "id": "usestate"
    }}>{`useState`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook replaces pairs of state getters and setters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class myComponent extends React.Component {
    state = {
      value: 'default'
    }

    handleChange = (e) => this.setState({
      value: e.target.value
    })

    render() {
      const { value } = this.state;

      return <input value={value} onChange={handleChange} />
    }
}
`}</code></pre>
    <p>{`👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const myComponent = () => {
  const [value, setValue] = useState('default');

  const handleChange = (e) => setValue(e.target.value)

  return <input value={value} onChange={handleChange} />
}
`}</code></pre>
    <p>{`Less code to write and understand.`}</p>
    <p>{`In a class component you:`}</p>
    <ul>
      <li parentName="ul">{`set a default value`}</li>
      <li parentName="ul">{`create an `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` callback that fires `}<inlineCode parentName="li">{`setState`}</inlineCode></li>
      <li parentName="ul">{`read value from state before rendering etc.`}</li>
    </ul>
    <p>{`Without modern fat arrow syntax you might run into trouble with binds.`}</p>
    <p>{`The hook approach moves that boilerplate to React's plate. You call `}<inlineCode parentName="p">{`useState`}</inlineCode>{`.
It takes a default value and returns a getter and a setter.`}</p>
    <p>{`You call that setter in your change handler.`}</p>
    <p>{`Behind the scenes React subscribes your component to that change. Your
component re-renders.`}</p>
    <h3 {...{
      "id": "useeffect"
    }}>{`useEffect`}</h3>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` replaces the `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`, `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`,
`}<inlineCode parentName="p">{`shouldComponentUpdate`}</inlineCode>{`, `}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{` quadfecta. It's like a
trifecta, but four.`}</p>
    <p>{`Say you want a side-effect when your component updates, like make an API call.
Gotta run it on mount and update. Want to subscribe to a DOM event? Gotta
unsubscribe on unmount.`}</p>
    <p>{`Wanna do all this only when certain props change? Gotta check for that.`}</p>
    <p>{`Class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class myComp extends Component {
  state = {
      value: 'default'
    }

    handleChange = (e) => this.setState({
      value: e.target.value
    })

    saveValue = () => fetch('/my/endpoint', {
        method: 'POST'
        body: this.state.value
    })

    componentDidMount() {
        this.saveValue();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.saveValue()
        }
    }

    render() {
      const { value } = this.state;

      return <input value={value} onChange={handleChange} />
    }
}
`}</code></pre>
    <p>{`👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const myComponent = () => {
  const [value, setValue] = useState('default');

  const handleChange = (e) => setValue(e.target.value)
  const saveValue = () => fetch('/my/endpoint', {
        method: 'POST'
        body: value
    })

    useEffect(saveValue, [value]);

  return <input value={value} onChange={handleChange} />
}
`}</code></pre>
    <p>{`So much less code!`}</p>
    <p><inlineCode parentName="p">{`useEffect`}</inlineCode>{` runs your function on `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`.
And that second argument, the `}<inlineCode parentName="p">{`[value]`}</inlineCode>{` part, tells it to run only when `}<inlineCode parentName="p">{`value`}</inlineCode>{`
changes.`}</p>
    <p>{`No need to double check with a conditional. If your effect updates the
component itself through a state setter, the second argument acts as a
`}<inlineCode parentName="p">{`shouldComponentUpdate`}</inlineCode>{` of sorts.`}</p>
    <p>{`When you return a method from `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, it acts as a `}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{`.
Listening to, say, your mouse position looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [mouseX, setMouseX] = useState();
const handleMouse = (e) => setMouseX(e.screenX);

useEffect(() => {
    window.addEventListener('mousemove', handleMouse);
    return () => window.removeEventListener(handleMouse);
})
`}</code></pre>
    <p>{`Neat 👌`}</p>
    <h3 {...{
      "id": "usecontext"
    }}>{`useContext`}</h3>
    <p><inlineCode parentName="p">{`useContext`}</inlineCode>{` cleans up your render prop callbacky hell.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const SomeContext = React.createContext()

// ...

<SomeContext.Consumer>
  {state => ...}
</SomeContext.Consumer>
`}</code></pre>
    <p>{`👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const state = useContext(SomeContext)
`}</code></pre>
    <p>{`Context state becomes just a value in your function. React auto subscribes to
all updates.`}</p>
    <p>{`And those are the core hooks. useState, useEffect, and useContext. You can use
them to build almost everything. 👌`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      